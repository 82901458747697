let arrow = document.getElementById("arrow");

if (arrow) {
  // if any h2 anchors in document are existing
  if (document.querySelectorAll("h2").length == 0) {
    arrow.style.display = "none";
  }

  arrow.addEventListener("click", () => {
    let h2Elems = document.querySelectorAll("h2");
    let visibleH2s = [];

    // if h2 is visible, push into array
    h2Elems.forEach((h2) => {
      let top = h2.getBoundingClientRect();
      if (top.top == 0) {
        return;
      } else {
        visibleH2s.push(h2);
      }
    });

    // for the first H2, set target
    let anchorYOffset = visibleH2s[0].getBoundingClientRect().top;
    let safeMargin = 150;
    let targetPosition = anchorYOffset - safeMargin;

    window.scrollTo({
      top: targetPosition,
      left: 0,
      behavior: "smooth",
    });
  });
}
